import styled from "styled-components";

import { COLORS, ELEVATION } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

const StyledFormSuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  box-shadow: ${ELEVATION.l_3};
  border-radius: 8px;
  padding: 32px;
  .successIcon {
    padding-bottom: 16px;
    text-align: center;
    img {
      display: inline-block;
    }
  }
  ${mq.below.md} {
    padding: 40px 24px;
  }
`;

export default StyledFormSuccessWrapper;

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledFormSuccessWrapper from "../shared/StyledFormSuccessWrapper";
import Paperform from "../contactus/Paperform";

import { StyledTitle3B, StyledSubTitle2, StyledDesc1 } from "../shared/StyledTypography";
import { textCenter } from "../shared/CommonStyled";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
`;

const StyledHeroBannerWrapper = styled.div`
  padding-bottom: 1450px;
  .mWidth700 {
    max-width: 700px;
    margin: 0 auto;
  }
  ${mq.below.md} {
  }
`;

const StyledFormWrapper = styled.div`
  margin-top: -1575px;
  .marketoFormWrapper {
    max-width: 820px;
  }
  ${mq.between("md", "lg")} {
    margin-top: -1540px;
  }
  ${mq.below.md} {
    margin-top: -1520px;
  }
`;

const HeroBanner = props => {
  const [isAliId, setIsAliId] = useState(false);
  const [isLocalResourceSurvey, setIsLocalResourceSurvey] = useState(false);
  const onSubmitCB = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("resourceSurveyConsent", "true");
      setIsLocalResourceSurvey(true);
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const searchAliId = searchParams.get("aliId");
    if (typeof window !== "undefined") {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const resourceSurveyConsent = window.localStorage.getItem("resourceSurveyConsent");
        if (resourceSurveyConsent) {
          setIsLocalResourceSurvey(true);
        }
      }
    }
    if (searchAliId || searchAliId === "") {
      setIsAliId(true);
    }
  }, [props.location.search]);
  return (
    <>
      <StyledSectionWrapperLightGray>
        <StyledContainerWrapper>
          <StyledHeroBannerWrapper>
            <div className="mWidth700">
              <StyledTitle3B paddingBottom="pb16" css={textCenter}>
                Data API Strategy Survey
              </StyledTitle3B>
              <StyledSubTitle2 css={textCenter}>
                Below is a short survey to learn more about API Strategy trends. After taking it,
                you will receive a link to choose a $25 gift card of your choice.
              </StyledSubTitle2>
            </div>
          </StyledHeroBannerWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapperLightGray>
      <StyledSectionWrapper>
        <StyledContainerWrapper>
          <StyledFormWrapper>
            {isAliId && isLocalResourceSurvey ? (
              <StyledFormSuccessWrapper>
                <div>
                  <StyledSubTitle2 fontWeight="font_600" paddingBottom="pb8" css={textCenter}>
                    Thank you for participating in this survey.
                  </StyledSubTitle2>
                  <StyledDesc1 css={textCenter}>
                    Please check your email for your gift card.
                  </StyledDesc1>
                </div>
              </StyledFormSuccessWrapper>
            ) : (
              <Paperform formId="dataapi" onSubmitCB={onSubmitCB} styleClass="marketoFormWrapper" />
            )}
          </StyledFormWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapper>
    </>
  );
};

export default HeroBanner;

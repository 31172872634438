import React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import HeroBanner from "../../components/resourcesurvey/HeroBanner";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/card.png",
};

const canonicalUrl = "https://hasura.io/resources/survey/";

const SurveyIndex = props => (
  <Layout location={props.location}>
    <Seo
      title="Data API Strategy Survey"
      description="Below is a short survey to learn more about API Strategy trends. After taking it, you will receive a link to choose a $25 gift card of your choice."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <HeroBanner location={props.location} />
  </Layout>
);

export default SurveyIndex;
